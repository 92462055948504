import React from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { slide as ReactMenu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BasePureComponent } from "common/components/Base";
import { clear } from "common/components/Breadcrumbs/actions";
import AppMenu from "app/components/Menu";
import { open as openMenu, close as closeMenu } from "./actions";
import i18n from "./i18n.json";
import "./styles.scss";

/* The application's menu. */
class Menu extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="d-print-none">
        {/* flyout menu */}
        <ReactMenu
          width="auto"
          pageWrapId="fsp-content"
          outerContainerId="fsp-app"
          isOpen={this.props.menuOpen}
          onStateChange={(state) => {
            // perform the change action
            this.props.menuChanged(state.isOpen);
          }}
        >
          {/* close menu */}
          <div>
            <div
              id="fsp-menu-close-container"
              className="fsp-menu-close-container pt-1 d-flex flex-row flex-grow"
            >
              <div className="flex-grow" />
              <div
                id="fsp-menu-close-icon"
                className={
                  "fsp-menu-close-icon" +
                  (this.props.menuOpen ? " active" : " inactive")
                }
                style={{ outline: "none" }}
                title={this.props.translate("menu.close")}
                tabIndex="0"
                aria-label={this.props.translate("menu.close")}
                role="button"
                aria-controls="navigation"
                onClick={() => {
                  // close the menu
                  this.props.closeMenu();

                  // reset breadcrumbs
                  this.props.clearBreadcrumbs();
                }}
              >
                <FontAwesomeIcon
                  icon="times"
                  title={this.props.translate("menu.close")}
                />
              </div>
            </div>
          </div>

          {/* app callback */}
          <AppMenu
            onClick={() => {
              // close the menu
              this.props.closeMenu();

              // reset breadcrumbs
              this.props.clearBreadcrumbs();
            }}
          />
        </ReactMenu>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // menu open?
  menuOpen: state.menu.open,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // menu changed
  menuChanged: (open) => {
    dispatch(open ? openMenu() : closeMenu());
  },

  // clear breadcrumbs
  clearBreadcrumbs: () => {
    dispatch(clear());
  },

  // close menu
  closeMenu: () => {
    dispatch(closeMenu());
  },
});

// turn this into a container component
export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Menu));
