import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";

import { BasePureComponent } from "common/components/Base";

/* Navigation redirection. */
class Navigate extends BasePureComponent {
  render() {
    // parent
    super.render();

    // fringe condition: navigating from a modal doesn't kill the backdrop
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();

    // supplemental props
    const s = this.props.supplemental ? this.props.supplemental : {};

    // build redirect URI with params
    const buildURI = (baseURI, supplemental) => {
      return {
        pathname: baseURI,
        search: supplemental.search
          ? `?search=${encodeURI(JSON.stringify(supplemental.search))}`
          : null,
      };
    };

    // this needs to stay in sync with the router mappings
    switch (this.props.destination) {
      case "home":
        return <Redirect to="/" push />;

      case "login":
        // make sure we don't nest redirects
        let redirect =
          s.redirect && !s.redirect.startsWith("/~%2F")
            ? "~" + encodeURIComponent(s.redirect)
            : s.redirect
            ? s.redirect
            : "";

        // prefix the redirect
        redirect = (redirect.startsWith("/") ? "" : "/") + redirect;

        // do it
        return <Redirect to={redirect} push />;

      case "profile":
        return <Redirect to="/account" push />;

      // administrators
      case "administrators":
        return <Redirect to={buildURI("/administrators", s)} push />;
      case "administrator":
        return (
          <Redirect
            to={`/administrators/administrator${
              s.administratorId ? `/${s.administratorId}` : ""
            }`}
            push
          />
        );

      // config
      case "config":
        return <Redirect to="/config" push />;

      // program years
      case "programYears":
        return <Redirect to="/config!programYears" push />;

      // parameters
      case "parameters":
        return <Redirect to="/config!parameters" push />;

      // snippets
      case "snippets":
        return <Redirect to={buildURI("/snippets", s)} push />;

      // sites
      case "sites":
        return <Redirect to={buildURI("/sites", s)} push />;
      case "site":
        return (
          <Redirect to={`/sites/site${s.siteId ? `/${s.siteId}` : ""}`} push />
        );
      case "siteEvents":
        return (
          <Redirect
            to={`/sites/site${s.siteId ? `/${s.siteId}!events` : ""}`}
            push
          />
        );
      case "siteClasses":
        return (
          <Redirect
            to={`/sites/site${s.siteId ? `/${s.siteId}!classes` : ""}`}
            push
          />
        );
      case "siteSiteYears":
        return (
          <Redirect
            to={`/sites/site${s.siteId ? `/${s.siteId}!siteYears` : ""}`}
            push
          />
        );

      // guardians
      case "guardians":
        return <Redirect to={buildURI("/guardians", s)} push />;
      case "guardian":
        return (
          <Redirect
            to={`/guardians/guardian${s.guardianId ? `/${s.guardianId}` : ""}`}
            push
          />
        );
      case "guardianEmergencyContacts":
        return (
          <Redirect
            to={`/guardians/guardian${
              s.guardianId ? `/${s.guardianId}!emergencyContacts` : ""
            }`}
            push
          />
        );
      case "guardianLegalGuardians":
        return (
          <Redirect
            to={`/guardians/guardian${
              s.guardianId ? `/${s.guardianId}!legalGuardians` : ""
            }`}
            push
          />
        );
      case "guardianScholars":
        return (
          <Redirect
            to={`/guardians/guardian${
              s.guardianId ? `/${s.guardianId}!scholars` : ""
            }`}
            push
          />
        );

      // volunteers
      case "volunteers":
        return <Redirect to={buildURI("/volunteers", s)} push />;
      case "volunteer":
        return (
          <Redirect
            to={`/volunteers/volunteer${
              s.volunteerId ? `/${s.volunteerId}` : ""
            }`}
            push
          />
        );

      // volunteer organizations
      case "volunteerOrganizations":
        return <Redirect to={buildURI("/volunteerOrganizations", s)} push />;
      case "volunteerOrganization":
        return (
          <Redirect
            to={`/volunteerOrganizations/volunteerOrganization${
              s.volunteerOrganizationId ? `/${s.volunteerOrganizationId}` : ""
            }`}
            push
          />
        );

      // scholars
      case "scholars":
        return <Redirect to={buildURI("/scholars", s)} push />;
      case "scholar":
        return (
          <Redirect
            to={`/scholars/scholar${
              s.guardianId && s.scholarId
                ? `/${s.guardianId}/${s.scholarId}`
                : ""
            }`}
            push
          />
        );

      // scholar applications
      case "scholarApplications":
        return <Redirect to={buildURI("/scholarApplications", s)} push />;
      case "scholarApplication":
        return (
          <Redirect
            to={`/scholarApplications/scholarApplication${
              s.guardianId && s.scholarId && s.scholarApplicationId
                ? `/${s.guardianId}/${s.scholarId}/${s.scholarApplicationId}`
                : ""
            }`}
            push
          />
        );
      case "enrollScholar":
        return (
          <Redirect
            to={`/scholarApplications/scholarApplication${
              s.guardianId && s.scholarId
                ? `/${s.guardianId}/${s.scholarId}`
                : ""
            }`}
            push
          />
        );

      // site staff
      case "siteStaff":
        return <Redirect to={buildURI("/siteStaff", s)} push />;
      case "siteStaffer":
        return (
          <Redirect
            to={`/siteStaff/siteStaffer${
              s.siteStaffId ? `/${s.siteStaffId}` : ""
            }`}
            push
          />
        );

      // site staff applications
      case "siteStaffApplications":
        return <Redirect to={buildURI("/siteStaffApplications", s)} push />;
      case "siteStaffApplication":
        return (
          <Redirect
            to={`/siteStaffApplications/siteStaffApplication${
              s.siteStaffId && s.siteStaffApplicationId
                ? `/${s.siteStaffId}/${s.siteStaffApplicationId}`
                : ""
            }`}
            push
          />
        );

      // site staff employee records
      case "siteStaffEmployeeRecords":
        return <Redirect to={buildURI("/siteStaffEmployeeRecords", s)} push />;
      case "siteStaffEmployeeRecord":
        return (
          <Redirect
            to={`/siteStaffEmployeeRecords/siteStaffEmployeeRecord${
              s.siteStaffId && s.siteStaffEmployeeRecordId
                ? `/${s.siteStaffId}/${s.siteStaffEmployeeRecordId}`
                : ""
            }`}
            push
          />
        );

      // activities
      case "activities":
        return <Redirect to={buildURI("/activities", s)} push />;
      case "activity":
        return (
          <Redirect
            to={`/activities/activity${
              s.activityId ? `/${this.props.supplemental.activityId}` : ""
            }`}
            push
          />
        );

      // calendars
      case "calendars":
        return <Redirect to="/reports/sites" push />;

      // default
      default:
        return null;
    }
  }
}

// set prop types and required-ness
Navigate.propTypes = {
  destination: PropTypes.string.isRequired,
  supplemental: PropTypes.object,
};

// set default props
Navigate.defaultProps = {};

export default Navigate;
