import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { BasePureComponent } from "common/components/Base";
import DataGuard from "common/components/DataGuard";
import Navigate from "common/components/Navigate";
import ErrorBoundary from "common/ErrorBoundary";
import Header from "./components/Header";
import Menu from "common/components/Menu";
import Footer from "./components/Footer";
import Main from "./Main";
import {
  fetchCurrentProgramYear,
  fetchAllProgramYears,
} from "common/entities/ProgramYear/actions";
import { sortArray } from "common/util";

/**
 * The main application.
 */
class App extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // for default search; we put this here because it is common to so many components
    this.state = {
      ...this.state,
      search: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // default search
    try {
      return {
        search:
          props.queryParams && props.queryParams["search"]
            ? JSON.parse(props.queryParams["search"])
            : null,
      };
    } catch (e) {
      console.error(
        `Error parsing search parameters: ${props.queryParams["search"]}`,
        e
      );
    }

    // no updates
    return null;
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div id="fsp-app" style={{ width: "100%", height: "100vh" }}>
        <nav>
          <Navigate {...this.props} />
          <Menu {...this.props} />
          <Header {...this.props} />
        </nav>
        <main id="fsp-content">
          <ErrorBoundary>
            <DataGuard
              data={{
                currentProgramYear: {
                  authenticated: true,
                  required: true,
                  fetcher: fetchCurrentProgramYear,
                },
                programYears: {
                  authenticated: true,
                  required: true,
                  fetcher: fetchAllProgramYears,
                  transformer: (programYears) => {
                    if (programYears) {
                      sortArray(programYears, "year", false);
                    }
                    return programYears;
                  },
                },
              }}
            >
              <Main {...this.props} search={this.state.search} />
            </DataGuard>
          </ErrorBoundary>
        </main>
        <footer>
          <Footer {...this.props} />
        </footer>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, props) => ({
  // the logged in administrator
  administrator: state.context.administrator,
});

// make router props accessible; this is necessary to
// drive re-renders based on path changes
export default withRouter(connect(mapStateToProps)(App));
