import PropTypes from "prop-types";
import requiredIf from "react-required-if";

import { BasePureComponent } from "common/components/Base";
import {
  hasAnyPermission,
  hasAllPermissions,
  hasPermission,
} from "common/util/session";

/* A wrapper to hide content from someone who doesn't have permission to see it. Can only be used when a user is logged in. */
class HasPermission extends BasePureComponent {
  render() {
    // parent
    super.render();

    // check if granted
    let granted = false;
    let negative = this.props.negative;

    // special case
    if (this.props.permissions === "") {
      granted = true;
      negative = false;
    } else if (this.props.permissions === "manageSite") {
      // another special case
      granted = hasPermission(this.props.permissions, this.props.target);
    } else {
      // split permissions?
      const permissions = this.props.permissions
        ? this.props.permissions.split(",")
        : [];

      // has permissions?
      granted = this.props.or
        ? hasAnyPermission(permissions)
        : hasAllPermissions(permissions);
    }

    // render
    return (granted && !negative) || (!granted && negative)
      ? this.props.children
      : null;
  }
}

// set prop types and required-ness
HasPermission.propTypes = {
  negative: PropTypes.bool,
  or: PropTypes.bool,
  permissions: PropTypes.string.isRequired,
  target: requiredIf(
    PropTypes.number,
    (props) =>
      props.permissions === "manageSite" || props.permissions === "viewSite"
  ),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

// set default props
HasPermission.defaultProps = { negative: false, or: true };

export default HasPermission;
