//
// This file contains application-specific variables, some of which
// are pulled via callbacks from common components.
//

// default page title
export const defaultTitle = "FSPConnect - Staff Console";

// default size for form widgets, in Bootstrap parlance ('sm' or 'lg')
export const formWidgetSize = "sm";

// default size for buttons, in Bootstrap parlance ('sm' or 'lg')
export const formButtonSize = "sm";

// default page size for lists
export const defaultPageSize = 25;

// max email attachments
export const maxEmailAttachments = 5;

// max size for email attachments, in bytes
export const maxEmailAttachmentSize = 2 * 1024 * 1024;

// max size for entity attachments, in bytes
export const maxEntityAttachmentSize = 3 * 1024 * 1024;

// max size for site staff résumés, in bytes
export const maxResumeSize = 1 * 1024 * 1024;

// include weekends in calendar-based functionality?
export const includeWeekends = false;

// the type of entity that uses this application
export const entityType = "administrators";

// styles for Google maps
export const mapStyles = [
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
